<template>
  <div class="bigDiv">
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/172257763983465569.jpg"
      alt=""
    />
    <div class="divs">
      <van-field
        v-model="query.mobile"
        center
        clearable
        placeholder="请输入手机号"
        :formatter="formatter"
        style="margin-top: 10px;margin-bottom: 15px;"
        :maxlength="11"
      >
      </van-field>
      <van-field v-model="code" center clearable placeholder="请输入短信验证码">
        <template #right-icon>
          <div
            size="small"
            type="primary"
            @click="getVerify"
            :class="{ blue: !flag }"
            class="vbtn"
          >
            {{ flag ? "获取验证码" : `${time}秒后可重新发送` }}
          </div>
        </template>
      </van-field>
      <div
        class="div"
        style="
      display: flex;"
      >
        <van-checkbox v-model="checked" class="checked"></van-checkbox
        ><span class="span"
          >报名即视为同意<a
            href="https://oss.hshwhkj.com/images/%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E6%8E%88%E6%9D%83%E4%B8%8E%E4%BF%9D%E6%8A%A4%E5%A3%B0%E6%98%8E.html"
            >《个人信息保护声明》</a
          ></span
        >
      </div>
      <div class="btn" @click="throttleSubmit">抢特价名额</div>
    </div>
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/17225776632987610.jpg"
      alt=""
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/17225836178904928.jpg"
      alt=""
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/172258366626702482.jpg"
      alt=""
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/08/02/172257770777586275.jpg"
      alt=""
      class="fixd"
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/07/30/172230660833736013.gif"
      alt=""
      class="btofixd"
      @click="throttleSubmit"
    />
  </div>
</template>

<script>
// import { getWxCsad } from "@/api/360AD.js";
import { getVerify, getTgUnionid, getTgUnifyPayH5 } from "../../api/components";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      checked: false,
      query: {
        mobile: "",
      },
      memberInfo: {
        open_id: "",
      },
      code: "",
      time: 60,
      flag: true,
      ruleForm: {
        land_link: "", //落地页链接
      },
    };
  },
  created() {
    const code = this.$route.query.code;
    this.code = this.$route.query.mobilecode || "";
    let currentUrl = new URL(window.location.href);
    this.query.mobile = this.$route.query.mobile;
    this.checked = this.$route.query.checked;
    currentUrl.searchParams.delete("mobile");
    currentUrl.searchParams.delete("mobilecode");
    currentUrl.searchParams.delete("code");
    currentUrl.searchParams.delete("state");
    this.ruleForm.land_link = currentUrl.href;
    if (code) {
      this.getTgUnionid();
    }
  },
  methods: {
    formatter(value) {
      // 仅保留输入的数字
      return value.replace(/\D/g, "");
    },
    async getVerify() {
      const res = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (res.test(this.query.mobile)) {
        if (this.flag) {
          this.flag = false;
          const data = await getVerify(this.query);
          this.startCountdown();
          console.log(data);
        }
      } else {
        Toast.fail("请输入正确的手机号!");
      }
    },
    startCountdown() {
      if (this.timer) {
        clearInterval(this.timer); // 清除之前的定时器
      }
      this.flag = false; // 重置结束标志
      this.time = 60; // 重置时间
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(this.timer); // 清除定时器
          this.flag = true; // 设置结束标志
          this.time = 60; // 重新设置时间
          return;
        }
        this.time -= 1; // 减少时间
      }, 1000);
    },
    timeore() {
      const times = setInterval(() => {
        if (this.time === 0) {
          this.flag = true;
          this.time = 60;

          return;
        }
        this.time = this.time - 1;
        this.timeore();
      }, 1000);
    },
    throttleSubmit() {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      const res = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (
        res.test(this.query.mobile) &&
        this.code !== "" &&
        this.code.length === 4
      ) {
        if (this.checked) {
          this.wxClick();
        } else {
          this.$message.error("请勾选同意协议!");
        }
      } else {
        this.$message.error("请输入正确的手机号/验证码");
      }
    },
    async wxClick() {
      const res = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/;
      if (
        res.test(this.query.mobile) &&
        this.code !== "" &&
        this.code.length === 4
      ) {
        const qyeru = {
          mobile: this.query.mobile,
          mobile_code: this.code,
          land_link: this.ruleForm.land_link,
        };
        if (this.is_weixn()) {
          this.getCode();
        } else {
          this.getTgUnifyPayH5();
        }
      } else {
        window.scrollTo(0, 0);
        Toast.fail("请输入正确的手机号/验证码!");
      }
    },

    is_weixn() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)) {
        return true;
      } else {
        return false;
      }
    },
    async getCode() {
      console.log(123, "获取code");

      let scope = "snsapi_userinfo"; //手動授權
      let info = {
        appid: "wx143b46f871cc09d6",
      };

      const parsedUrl = new URL(window.location.href);
      const basAAA = parsedUrl.origin;
      const redirectUrl = basAAA;
      const { code, mobile, mobilecode, checked, ...rest } = this.$route.query;

      // 将剩余字段转换为查询字符串
      const queryString = new URLSearchParams(rest).toString();

      console.log(123, queryString);
      window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          info.appid
        }&redirect_uri=${encodeURIComponent(
          `${redirectUrl}/txApay7?checked=${this.checked}&mobile=${this.query.mobile}&mobilecode=${this.code}&${queryString}`
        )}&response_type=code&scope=${scope}#wechat_redirect`
      );
      // }
    },
    async getTgUnionid() {
      try {
        const query = {
          code: this.$route.query.code,
          mobile: this.query.mobile,
          mobile_code: this.code,
          land_link: this.ruleForm.land_link,
          appid: "wx143b46f871cc09d6",
        };
        const { data } = await getTgUnionid(query);
        this.memberInfo = data.data;
        console.log(data.data);
        console.log(this.memberInfo, "88");
        this.$toast.loading("正在加载,请稍等~");
        this.getTgUnifyPayH5();
      } catch (error) {
        console.log(error);
      }
    },
    async getTgUnifyPayH5() {
      try {
        const query = {
          appid: "wx143b46f871cc09d6",
          open_id: this.memberInfo.openid,
          price: this.$route.query.price || 0,
          unionid: this.memberInfo.unionid,
          headimgurl: this.memberInfo.headimgurl,
          wechat_name: this.memberInfo.nickname,
          mobile: this.query.mobile,
          mobile_code: this.code,
          land_link: this.ruleForm.land_link,
        };
        const res = await getTgUnifyPayH5(query);
        console.log(res, "8897879");
        this.wechatPay(res);
      } catch (error) {
        console.log(error, "我是错误");
        // this.getCode()
      }
    },

    wechatPay(res) {
      if (this.is_weixn()) {
        const {
          appId,
          timestamp,
          nonceStr,
          signature,
          paySign,
          signType,
          trade_no,
        } = res.data.data.data;

        wx.config({
          debug: false,
          appId: "wx143b46f871cc09d6",
          timestamp,
          nonceStr,
          signature,
          jsApiList: ["chooseWXPay"],
        });
        this.$toast.clear();

        wx.chooseWXPay({
          timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          nonceStr, // 支付签名随机串，不长于 32 位
          package: res.data.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign, // 支付签名
          success: (res) => {
            console.log("成功");
            // this.isPay = true
            // this.ISshow = true
            // window.location.href = 'https://work.weixin.qq.com/ca/cawcdee1a6bfffe85c?customer_channel=zmwk_BRYtC_c_{}_p_{}'

            // 支付成功后的回调函数
            this.$router.replace({
              path: "/txTgPayOk",
              query: {
                trade_no: trade_no,
              },
            });
          },
        });
      } else {
        console.log(res.data.data.data);
        window.location.href = res.data.data.data;
      }
    },
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
* {
  font-family: "Microsoft YaHei";
}
.bigDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #b5f1f9;
  .div {
    width: 310px;
    margin: 0 auto;
    .span {
      color: #666;

      a {
        color: #666;
        border-bottom: 1px solid #666;
      }
    }
    .checked {
      margin: 0 auto;
      font-size: 16px;
      color: #666;
    }
    font-size: 16px;
  }

  .blue {
    background-color: #ccc !important;
    border: none;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
  .fixd {
    margin-bottom: 120px;
  }
  .divs {
    background-color: #b5f1f9;
    margin: 20px 0;
  }
  .btofixd {
    width: 375px;
    position: fixed;
    bottom: 0;
  }
  .vbtn {
    width: 120px;
    height: 45px;
    border-radius: 8px;
    border: none;
    background-color: #0ec9b0;
    color: #fff;
    text-align: center;
    line-height: 45px;
    position: relative;
    right: -16px;
    font-size: 16px;
    font-weight: 700;
  }
  .btn {
    width: 320px;
    height: 40px;
    border-radius: 20px;
    margin: 10px auto;
    text-align: center;
    line-height: 40px;
    background-color: #f76f17;
    color: #fff;
    font-size: 24px;
    animation: pulse 1s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .div {
      width: 310px;
      margin: 0 auto;
      .span {
        color: #666;

        a {
          color: #666;
          border-bottom: 1px solid #666;
        }
      }
      .checked {
        margin: 0 auto;
        font-size: 16px;
        color: #666;
      }
      font-size: 16px;
    }
    .van-field__control {
      font-size: 16px !important;
    }
    img {
      width: 100%;
      object-fit: contain;
    }
    .fixd {
      margin-bottom: 50px;
    }
    .btofixd {
      width: 750px;
      position: fixed;
      bottom: 0;
    }
    .vbtn {
      height: 40px;
      border-radius: 8px;
    }
    .van-field {
      width: 750px;
      margin: 0 auto;
      margin-bottom: 5px;
      border-radius: 12px;
      height: 50px;
    }
    .van-cell {
      background-color: #e8ebe8;
    }
    .btn {
      width: 750px;
      height: 40px;
      border-radius: 20px;
      margin: 10px auto;
      text-align: center;
      line-height: 40px;
      background-color: #ff0000;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      animation: pulse 1s infinite;
    }
  }
}
.van-field {
  width: 320px;
  margin: 0 auto;
  margin-bottom: 5px;
  border-radius: 12px;
  height: 45px;
}
</style>
<style>
.van-field__control {
  font-size: 16px !important;
}
</style>
